(function($, window, document) { // jQuery wrapper

    /**
     * Ajax Functions
     */
    window.sliders = {

    	/**
    	 * Global load more button functionality
    	 */
        initSlider: function ($el, $args){
        	$el.slick($args);
        },

        genericSlider: function() {
            $el = $('.js--generic-slider');

            $el.each(function() {
                $arrows = $el.data('slick-arrows');
                $dots = $el.data('slick-dots');
                $autoplay = $el.data('slick-autoplay');
                $autoplay_speed = $el.data('slick-autoplayspeed');
                $arrows_placement = $el.data('slick-arrows-placement');

                $args = {
                    arrows:$arrows,
                    arrowsPlacement: $arrows_placement,
                    dots: $dots,
                    infinite: false,
                    autoplay: $autoplay,
                    autoplaySpeed: $autoplay_speed,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    adaptiveHeight: true,
                    customPaging : function(slider, i) {
                        return '<div class="slick-button"></div>';
                    },
                };
                window.sliders.initSlider( $el, $args );
            });
        }


    };

}(window.jQuery, window, document)); // End of jQuery wrapper
