(function($, window, document) {
    // jQuery wrapper

    // Page Functions
    window.PageFunctions = {
        /**
         *  Init AOS.js
         *  @link https://michalsnik.github.io/aos/
         */
        initAOS: function() {
            AOS.init();
        },

        /**
         * Loads the social media "share" links into a new, small window
         */
        shareFunctionality: function() {
            $(".share-post a.social-link").click(function(e) {
                e.preventDefault();
                window.open(
                    $(this).attr("href"),
                    "fbShareWindow",
                    "height=450, width=550, top=" +
                        ($(window).height() / 2 - 275) +
                        ", left=" +
                        ($(window).width() / 2 - 225) +
                        ", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
                );
                return false;
            });
        },

        /**
         * Mobile Menu functionality
         */
        mobileMenu: function() {
            $(".menu-trigger-container").on("click", function(e) {
                if ($("body").hasClass("menu-open")) {
                    $("body").removeClass("menu-open");
                } else {
                    $("body").addClass("menu-open");
                }
            });
        },

        /**
         * Scroll to anchor links
         */
        scrollToAnchorLinks: function() {
            $(".wrap a[href^='#']:not(.noscroll)").on("click", function(e) {
                e.preventDefault();
                href = $(this).attr("href");
                target = $(href);
                $offset = target.data('offset');

                if (!$offset) {
                    $offset = 165;
                }

                if (href.length > 1) {
                    $("html, body").animate(
                        {
                            scrollTop: target.offset().top - $offset
                        },
                        500
                    );
                }
            });
        },

        /**
         * Updates PHP search parameters
         */
        updateSearchParameters: function(uri, key, value) {
            var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
            var separator = uri.indexOf("?") !== -1 ? "&" : "?";
            if (uri.match(re)) {
                return uri.replace(re, "$1" + key + "=" + value + "$2");
            } else {
                return uri + separator + key + "=" + value;
            }
        },


        /**
         * Mobile menu accordion
         */
        mobileMenuAccordion: function() {
            // expand selected accordion
            $(".menus.mobile .menu-item-has-children > a").on("click", function(e) {
                e.preventDefault();
                $(this)
                    .next()
                    .slideToggle();
                $(this).parent().toggleClass("sub-menu-open");
            });
        },

        /**
         * Accordion
         */
        accordion: function() {
            // expand selected accordion
            $(".js-accordion--title").on("click", function(e) {
                e.preventDefault();
                $(this)
                    .next()
                    .slideToggle();
                $(this).toggleClass("active");
            });
        },

        /**
         *  Add shrink class to header on scroll
         */
        shrinkHeaderOnScroll: function() {
            var $threshold = 33,
                $window = $(window);

            $window.scroll(function() {
                $scroll_position = $window.scrollTop();
                if ($scroll_position > $threshold) {
                    $("body").addClass("shrink");
                } else {
                    $("body").removeClass("shrink");
                }
            });

            // if the scroll position is greater than 0, add the shrink class
            var height = $(window).scrollTop();
            if (height > 0) {
                $("body").addClass("shrink");
            }
        },

        /**
         * Hide header on scroll
         */
        hideHeaderOnScroll: function() {
            var prevScrollpos = window.pageYOffset;
            window.onscroll = function() {
                var currentScrollPos = window.pageYOffset;

                if (currentScrollPos > 0) {
                    $("body").removeClass("top");
                } else {
                    $("body").addClass("top");
                }

                if (prevScrollpos > currentScrollPos) {
                    $("body").removeClass("hide-header");
                } else {
                    $("body").addClass("hide-header");
                }
                prevScrollpos = currentScrollPos;
            };
        },

        /**
         * Custom lity trigger for uniquely styled video embed code popup
         */
        videoPopupLity: function() {
            var options = {
                template:
                    '<div class="lity lity-ingredients" role="dialog" aria-label="Dialog Window (Press escape to close)" tabindex="-1"><div class="lity-wrap" data-lity-close role="document"><div class="lity-loader" aria-hidden="true">Loading...</div><div class="lity-container"><div class="lity-content"></div><button class="lity-close" type="button" aria-label="Close (Press escape to close)" data-lity-close>&times;</button></div></div></div>'
            };

            $(document).on("click", "[data-ingredients-lity]", function(e) {
                lity($(this).attr("href"), options, $(this));
            });
        },

        /**
         * Wrap all WYSIWYG tables in responsive element
         */
        wrapWYSIWYGTables: function() {
            $(".basic-content--content table").wrap(
                "<div class='responsive-table'></div>"
            );
        },

        /**
         * Init Fit Vids
         */
        initFitVids: function() {
            $(".basic-content--content").fitVids({ customSelector: "iframe[src^='https://www.facebook.com']"});
        },

        /**
         * Disable right magic mouse swipe
         */
        disableRightMagicMouse: function() {

            document.addEventListener('DOMMouseScroll', function(e) { 
                if (e.axis === e.HORIZONTAL_AXIS) {
                    e.stopPropagation(); 
                    e.preventDefault();
                    e.cancelBubble = false; 
                }
                return false;
            }, false);
        },
        /**
         * Close Floating Coupon Box
         */
        closeFloatingCouponBox: function() {
            $(".floating-coupon--close").on('click', function() {
                $(".floating-coupon").fadeOut();
            });
        },
    };
})(window.jQuery, window, document); // End of jQuery wrapper
